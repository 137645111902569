// @ts-nocheck
import { useDispatch, useSelector } from 'react-redux';

import { useCognito } from '@uptime/shared/providers/CognitoProvider';

import { getToken, getUserType, getUserNameCognito } from '../../store/selectors/auth';
import { signOutCognito } from '../../store/actions/auth';

const useAuth = () => {
  const dispatch = useDispatch();
  const isActiveSession = JSON.parse(localStorage.getItem('isActiveSession') || null);

  const token = useSelector(getToken);
  const userName = useSelector(getUserNameCognito);
  const userType = useSelector(getUserType);
  const { isAuthorized } = useCognito();
  const { userId } = token || {};

  return {
    token,
    userId: userId ? parseInt(userId) : null,
    userName,
    userType,
    isActiveSession,
    isLoggedIn: isAuthorized,
    onSignOut: () => dispatch(signOutCognito()),
  };
};

export default useAuth;
