import { nanoid } from 'nanoid';
import DevicesIcon from '@mui/icons-material/Devices';
import PeopleIcon from '@mui/icons-material/People';
import BusinessIcon from '@mui/icons-material/Business';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import WrenchIcon from '@mui/icons-material/Build';
import PenIcon from '@mui/icons-material/Create';
import UsersIcon from '@mui/icons-material/People';
import TaskIcon from '@mui/icons-material/PlaylistAddCheck';
import Checklist from '@mui/icons-material/Checklist';
import SegmentOutlined from '@mui/icons-material/SegmentOutlined';

export const menuItems = [
  {
    id: nanoid(),
    icon: <PeopleIcon />,
    primary: 'Users',
    url: '/users',
  },
  {
    id: nanoid(),
    icon: <BusinessIcon />,
    primary: 'Facilities',
    url: '/facilities',
  },
  {
    id: nanoid(),
    icon: <DevicesIcon />,
    primary: 'Devices',
    url: '/devices/',
  },
  {
    id: nanoid(),
    icon: <AssignmentIcon />,
    primary: 'Service Requests',
    url: '/work-orders',
  },
];

export const LogItems = [
  {
    id: nanoid(),
    icon: <SegmentOutlined />,
    primary: 'Log Types',
    url: '/logs/logtypes',
  },
  {
    id: nanoid(),
    icon: <Checklist />,
    primary: 'Completed Logs',
    url: '/logs/completed',
  },
];

export const analyticsItems = [
  {
    id: nanoid(),
    icon: <TaskIcon />,
    primary: 'Activities',
    url: '/analytics/d6x5mvkxoR',
  },
  {
    id: nanoid(),
    icon: <WrenchIcon />,
    primary: 'Service Requests',
    url: '/analytics/EbY2aj7AyD',
  },
  {
    id: nanoid(),
    icon: <DevicesIcon />,
    primary: 'Devices',
    url: '/analytics/38xvZM5x2j',
  },
  {
    id: nanoid(),
    icon: <BusinessIcon />,
    primary: 'Facilities',
    url: '/analytics/7Jx6raKxwe',
  },
  {
    id: nanoid(),
    icon: <TrendingUpIcon />,
    primary: 'Financials',
    url: '/analytics/8L19X63x56',
  },
  {
    id: nanoid(),
    icon: <PenIcon />,
    primary: 'Warranty & Contracts',
    url: '/analytics/aXxpzzkAqP',
  },
  {
    id: nanoid(),
    icon: <UsersIcon />,
    primary: 'Users',
    url: '/analytics/5L1BpQbYqe',
  },
];
