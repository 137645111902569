import { createContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

interface Props {
  children: ReactNode;
}

type UIContextType = {
  sidebarIsOpen: boolean;
  setSidebarIsOpen: Dispatch<SetStateAction<boolean>>;
};

const initialState = {
  sidebarIsOpen: true,
  setSidebarIsOpen: () => {},
};

export const UIContext = createContext<UIContextType>(initialState);

const UIProvider = ({ children }: Props) => {
  const [sidebarIsOpen, setSidebarIsOpen] = useState(initialState.sidebarIsOpen);

  return (
    <UIContext.Provider
      value={{
        sidebarIsOpen,
        setSidebarIsOpen,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export default UIProvider;
