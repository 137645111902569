// @ts-nocheck
import { useState } from 'react';
import { menuItems, analyticsItems, LogItems } from '../listItems';
import { ListItem, ListItemIcon, ListItemText, Collapse, List } from '@mui/material';
import WrapLink from '@uptime/shared/components/WrapLink';
import { useLocation } from 'react-router-dom';
import { ExpandLess, ExpandMore, Assessment } from '@mui/icons-material';
import ListAltIcon from '@mui/icons-material/ListAlt';

const expandableItems = {
  logs: 'logs',
  analytics: 'analytics',
};

const Menu = () => {
  const [open, setOpen] = useState(null);
  const { pathname } = useLocation();

  const handleClick = (item) => {
    setOpen(item === open ? null : item);
  };

  return (
    <div>
      {menuItems.map((menu) => (
        <ListItem
          component={WrapLink}
          to={menu.url}
          key={menu.id}
          selected={pathname.includes(menu.url)}
          button
          data-testid="leftMenuItem"
        >
          <ListItemIcon>{menu.icon}</ListItemIcon>
          <ListItemText primary={menu.primary} />
        </ListItem>
      ))}
      <ListItem button onClick={() => handleClick(expandableItems.logs)} data-testid="leftMenuItem">
        <ListItemIcon>
          <ListAltIcon />
        </ListItemIcon>
        <ListItemText primary="Logs" />
        {open === expandableItems.logs ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open === expandableItems.logs} timeout={150} unmountOnExit>
        <List>
          {LogItems.map((menu) => (
            <ListItem
              component={WrapLink}
              to={menu.url}
              key={menu.id}
              selected={pathname === menu.url}
              button
              style={{ paddingLeft: menu.icon ? 27 : 80 }}
              data-testid="leftMenuItem"
            >
              {menu.icon && <ListItemIcon>{menu.icon}</ListItemIcon>}
              <ListItemText primary={menu.primary} />
            </ListItem>
          ))}
        </List>
      </Collapse>

      <ListItem button onClick={() => handleClick(expandableItems.analytics)}>
        <ListItemIcon>
          <Assessment />
        </ListItemIcon>
        <ListItemText primary="Analytics" />
        {open === expandableItems.analytics ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open === expandableItems.analytics} timeout={150} unmountOnExit>
        <List>
          {analyticsItems.map((menu) => (
            <ListItem
              component={WrapLink}
              to={menu.url}
              key={menu.id}
              selected={pathname === menu.url}
              button
              style={{ paddingLeft: 27 }}
            >
              <ListItemIcon>{menu.icon}</ListItemIcon>
              <ListItemText primary={menu.primary} />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </div>
  );
};

export default Menu;
