import React, { createContext, useCallback, useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { CognitoUserSession } from 'amazon-cognito-identity-js';

import * as cognito from '../services/cognito';
import { useOnMount, useOnUpdate } from '../hooks';
import { ERROR_MESSAGES } from '../constants';
import { COGNITO_IDLE_MESSAGE, COGNITO_KEYS, IDLE_COGNITO_MESSAGE_VALUE } from '../constants/cognito';

type Context = {
  resetPassword: (username: string, code: string, password: string) => Promise<any>;
  isAuthorized: boolean;
  isLoadingSession: boolean;
  setShowIdleMessage: () => string | null | undefined;
  federatedSingIn: () => any;
  getFederatedUserSession: () => Promise<any>;
  federatedSignOut: () => void;
  changePassword: (oldPassword: string, newPassword: string) => Promise<any>;
};

type Props = {
  children: React.ReactNode;
  cognitoEnv: string;
};

type CognitoError = {
  code: string;
  name: string;
};

const CognitoContext = createContext<Context>({
  resetPassword: async (username: string, code: string, password: string) => {},
  isAuthorized: false,
  isLoadingSession: true,
  setShowIdleMessage: () => '',
  federatedSingIn: () => {},
  getFederatedUserSession: async () => {},
  federatedSignOut: () => {},
  changePassword: async (oldPassword: string, newPassword: string) => {},
});

export const useCognito = () => useContext(CognitoContext);

const getPayloads = (session: { accessToken: { payload: any }; idToken: { payload: any } }) => ({
  accessTokenPayload: session.accessToken.payload,
  idTokenPayload: session.idToken.payload,
});

const getTokens = (session: {
  accessToken: { jwtToken: any };
  refreshToken: { token: any };
  idToken: { jwtToken: any };
}) => ({
  accessToken: session.accessToken.jwtToken,
  refreshToken: session.refreshToken.token,
  idToken: session.idToken.jwtToken,
});

const setTokensInStorage = (
  {
    accessToken,
    refreshToken,
    idToken,
  }: {
    accessToken: string;
    refreshToken: string;
    idToken: string;
  },
  cognitoEnv: string
) => {
  localStorage.setItem(COGNITO_KEYS(cognitoEnv).ACCESS_TOKEN, accessToken);
  localStorage.setItem(COGNITO_KEYS(cognitoEnv).ID_TOKEN, idToken);
  localStorage.setItem(COGNITO_KEYS(cognitoEnv).REFRESH_TOKEN, refreshToken);
};

const setPayloadsInStorage = (
  {
    idTokenPayload,
    accessTokenPayload,
  }: {
    idTokenPayload: object;
    accessTokenPayload: object;
  },
  cognitoEnv: string
) => {
  localStorage.setItem(COGNITO_KEYS(cognitoEnv).ACCESS_TOKEN_PAYLOAD, JSON.stringify(accessTokenPayload));
  localStorage.setItem(COGNITO_KEYS(cognitoEnv).ID_TOKEN_PAYLOAD, JSON.stringify(idTokenPayload));
};

const storeTokensInStorage = (session: any, cognitoEnv: string) =>
  setTokensInStorage(getTokens(session), cognitoEnv);
const storePayloadsInStorage = (session: any, cognitoEnv: string) =>
  setPayloadsInStorage(getPayloads(session), cognitoEnv);

const resetStorage = (cognitoEnv: string) =>
  Object.values(COGNITO_KEYS(cognitoEnv)).forEach((key: any) => localStorage.removeItem(key));

const setShowIdleMessageInStorage = (v: IDLE_COGNITO_MESSAGE_VALUE, cognitoEnv: string) =>
  localStorage.setItem(COGNITO_IDLE_MESSAGE(cognitoEnv), v);
const setShowIdleMessageFromStorage = (cognitoEnv: string) =>
  localStorage.getItem(COGNITO_IDLE_MESSAGE(cognitoEnv)) || IDLE_COGNITO_MESSAGE_VALUE.HIDE;

const storeDetailsInStorage = (session: CognitoUserSession, cognitoEnv: string) => {
  resetStorage(cognitoEnv);
  [storeTokensInStorage, storePayloadsInStorage].map((f) => f(session, cognitoEnv));
};

const COGNITO_ERROR = {
  LimitExceededException: 'LimitExceededException',
  UserNotFoundException: 'UserNotFoundException',
  CodeMismatchException: 'CodeMismatchException',
  NotAuthorizedException: 'NotAuthorizedException',
  InvalidParameterException: 'InvalidParameterException',
  ExpiredCodeException: 'ExpiredCodeException',
};

const ERROR_MAPPER = {
  // triggered when rate limit is reached
  [COGNITO_ERROR.LimitExceededException]: ERROR_MESSAGES.RATE_LIMIT,
  // triggered when user does not exist
  [COGNITO_ERROR.UserNotFoundException]: ERROR_MESSAGES.INVALID_USERNAME_OR_PASSWORD,
  // triggered when user code does not match aws cognito code
  [COGNITO_ERROR.CodeMismatchException]: ERROR_MESSAGES.INVALID_VERIFICATION_CODE_OR_EMAIL,
  // triggered when user is disabled or email address is invalid
  [COGNITO_ERROR.NotAuthorizedException]: ERROR_MESSAGES.INVALID_USERNAME_OR_PASSWORD,
  // triggered when email is not verified or invalid verification code
  [COGNITO_ERROR.InvalidParameterException]: ERROR_MESSAGES.INVALID_USERNAME_OR_PASSWORD,
  // triggered when code expired
  [COGNITO_ERROR.ExpiredCodeException]: ERROR_MESSAGES.INVALID_VERIFICATION_CODE_OR_EMAIL,
};

const getError = (error: CognitoError) => {
  const message = ERROR_MAPPER[error.code];

  if (message) {
    return message;
  }

  return ERROR_MESSAGES.OOPS;
};

const ONE_MINUTE = 60 * 1000;
const ONE_HOUR = ONE_MINUTE * 60;

export const CognitoProvider = ({ children, cognitoEnv }: Props) => {
  const history = useHistory();
  const intervalRef = useRef(null);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoadingSession, setIsLoadingSession] = useState(true);
  const [session, setSession] = useState<CognitoUserSession | null>(null);

  const { start, pause } = useIdleTimer({
    timeout: ONE_HOUR,
    onAction: () => {},
    onActive: () => {},
    onIdle: () => {
      setShowIdleMessageInStorage(IDLE_COGNITO_MESSAGE_VALUE.SESSION, cognitoEnv);
      setIsAuthorized(false);
    },
    debounce: 1000,
    startManually: true,
  });

  useOnMount(() => {
    const setUserSession = async () => {
      try {
        const session = await cognito.getUserSession();

        storeDetailsInStorage(session, cognitoEnv);
        setSession(session);
        setIsAuthorized(true);
        setIsLoadingSession(false);

        start();
      } catch (e) {
        console.log("[info]: cannot get user's session, one more attempt");
        setIsAuthorized(false);
        setIsLoadingSession(false);
      }
    };

    !isAuthorized && setUserSession();
  });

  useOnUpdate(() => {
    if (isAuthorized) return;

    cognito.signOut();
    resetStorage(cognitoEnv);
    setSession(null);

    pause();

    history.push('/login');
  }, [isAuthorized]);

  useOnUpdate(() => {
    if (!session) return;

    const scheduleRefresh = () => {
      try {
        const expiration = session.getAccessToken().getExpiration();

        const refreshToken = session.getRefreshToken().getToken();

        // @ts-ignore
        intervalRef.current = setTimeout(
          async () => {
            try {
              console.log('[info]: about to renew user session');
              const session = await cognito.refreshSession(refreshToken);

              if (!Boolean(session)) {
                console.log("[error]: cannot renew user's session, session is empty, signing out");
                setShowIdleMessageInStorage(IDLE_COGNITO_MESSAGE_VALUE.SESSION, cognitoEnv);
                setIsAuthorized(false);
                return;
              }

              storeDetailsInStorage(session, cognitoEnv);
              setSession(session);
              setIsAuthorized(true);
              console.log('[info]: user session has been refreshed successfully');
            } catch (e) {
              console.log("[error]: cannot renew user's session, signing out");
              setShowIdleMessageInStorage(IDLE_COGNITO_MESSAGE_VALUE.SESSION, cognitoEnv);
              setIsAuthorized(false);
            }
          },
          expiration * 1000 - ONE_MINUTE - Date.now()
        );
      } catch (e) {
        console.log('[error]: schedule refresh, signing out');
        setShowIdleMessageInStorage(IDLE_COGNITO_MESSAGE_VALUE.SESSION, cognitoEnv);
        setIsAuthorized(false);
      }
    };

    scheduleRefresh();

    return () => intervalRef.current && clearInterval(intervalRef.current);
  }, [session?.getAccessToken().getExpiration()]);

  const federatedSignOut = useCallback(() => {
    setIsAuthorized(false);
    setShowIdleMessageInStorage(IDLE_COGNITO_MESSAGE_VALUE.LOGOUT, cognitoEnv);
  }, []);

  const resetPassword = useCallback(async (username: string, oldPassword: string, newPassword: string) => {
    try {
      const session = await cognito.resetPassword(username, oldPassword, newPassword);

      storeDetailsInStorage(session, cognitoEnv);
      setSession(session);
    } catch (e) {
      console.log('[error]: failed attempt to reset password');
      // @ts-ignore
      console.log(e?.stack);
      const mfaErrorPattern = 'mfaSetup is not a function';

      // @ts-ignore
      if (e?.name === 'TypeError' && e?.stack?.includes(mfaErrorPattern)) {
        return;
      }
      throw new Error(getError(e as CognitoError));
    }
  }, []);

  const setShowIdleMessage = useCallback(() => {
    const value = setShowIdleMessageFromStorage(cognitoEnv);

    setShowIdleMessageInStorage(IDLE_COGNITO_MESSAGE_VALUE.HIDE, cognitoEnv);
    return value;
  }, []);

  const federatedSingIn = useCallback(cognito.federatedSingIn, []);

  const getFederatedUserSession = useCallback(async () => {
    try {
      const { signInUserSession } = await cognito.getFederatedUserSession();
      storeDetailsInStorage(signInUserSession, cognitoEnv);
      setSession(signInUserSession);
      setIsAuthorized(true);
      setIsLoadingSession(false);
    } catch (e) {
      console.log('[error]: failed attempt to get federated user session');
      console.log('[error]: redirecting user to hosted ui');
      setIsAuthorized(false);
      setIsLoadingSession(false);
    }
  }, []);

  const changePassword = useCallback(async (oldPassword: string, newPassword: string) => {
    try {
      await cognito.changePassword(oldPassword, newPassword);
    } catch (e) {
      console.log('[error]: failed attempt to change password');
      console.log(JSON.stringify(e, null, 2));
      throw new Error(getError(e as CognitoError));
    }
  }, []);

  return (
    <CognitoContext.Provider
      value={{
        isAuthorized,
        isLoadingSession,
        resetPassword,
        setShowIdleMessage,
        federatedSingIn,
        federatedSignOut,
        getFederatedUserSession,
        changePassword,
      }}
    >
      {children}
    </CognitoContext.Provider>
  );
};
