import { useContext } from 'react';
import { checkPermission } from '@uptime/shared/utils/permissions';

import { PermissionsContext } from '../../providers/PermissionsProvider';

const usePermissions = () => {
  const { permissions, setPermissions, hasPermissionsLoaded, setHasPermissionsLoaded } =
    useContext(PermissionsContext);

  // Dummy permission, will be replaced when there are some features
  const adminPermission = checkPermission('admin', permissions);

  return {
    setPermissions,
    hasPermissionsLoaded,
    setHasPermissionsLoaded,
    adminPermission,
  };
};

export default usePermissions;
